.App-logo {
  height: 100px;

}

* {
  transition: all 0.5s ease;
}

header {
  background-color: black;
}

header.purps {
  background-color: purple;
}

.btn {
  background-color: black;
  color: purple;
  font-weight: 700;
  font-size: 24px;
  border: 0;
  text-align: center;
}

.selected-doc {

  opacity: .5;
}

.secrect-wammy {
  background-color: purple;
  padding: 0;
  border-radius: 0;
}

.secrect-wammy input {
  margin: 5px 0;
  font-size: 24px;
  font-weight: 700;
}

.ship-shelf-title-container {
  overflow-x: scroll;
  width: 100%;
}

.add-self-button.btn {
  margin: 0 ;

  margin-top: .60rem;
  background-color: purple;
  color: black;

}

.ship-auth-btn {
  font-weight: 400;
  margin: 1.25rem;
  width: fit-content;
}

p.btn {
  font-size: 24px;
  color: black;
  background-color: purple;
  cursor: default !important;
  border-radius: 0px;
  font-weight: 900;
  margin: 0;
  width: fit-content;
}

a.btn.fa-external-link {
  background-color: transparent;
  color: purple;
  font-size: 24px;
  font-weight: 400;
}


header.purps i.btn.fa-toggle-on {
  background-color: purple;
  color: black;
}


header.purps i.btn.fa-home {
  background-color: purple;
  color: black;
}


i.btn.fa-toggle-on {
  background-color: black;
  color: purple;
  transform: rotate(0deg);
  transition: all 0.5s ease;
}

i.btn.fa-toggle-on.swtiched {
  transition: all 0.5s ease;
  transform: rotate(180deg);
  padding: 0;
}

i.btn.fa-trash {
  background-color: transparent;
  color: purple;
  font-size: 24px;
  font-weight: 400;
}

button.btn.ship-nav-title {
  width: 100%;
}


input.slight-filter {
  width: 500px
}

input.form-control {
  font-size: 24px;
  font-weight: 700;
  padding: .1rem .75rem;

  color: purple;
  border: 0;
  background-color: black;
  box-shadow: unset;

}

input.form-control:focus {
  font-size: 24px;
  font-weight: 700;
  padding: .1rem .75rem;
  color: purple;
  border: 0;
  background-color: black;
  box-shadow: unset;
}

.page-search-container {
  overflow-x: scroll;
}

.page-search-width {
  width: 200%;
}

.shelf-page-content-container {
  overflow: hidden;
}

.btn.page-link {
  color: black;
  background-color: purple;
  font-weight: 400;
  margin-right: .25rem;
  padding: .25rem .75rem;

}


.page-limit .btn.page-link {
  color: purple;
  background-color: black;
  font-weight: 900;
  margin-right: .25rem;
  padding: .25rem .75rem;
}

.add-shelf-form {
  background-color: white;
  padding: 1.25rem;
}

.add-shelf-form input {
  font-weight: 900;

}
.add-shelf-form input.input-lg {
  font-weight: 900;
  padding: .375rem .75rem;
  margin: 0;
  margin-bottom: .60rem ;
}

@keyframes img-place-hold {
  from {
    color: purple;
    background-color: black;
  }

  50% {
    color: black;
    background-color: purple;
  }

  to {
    color: purple;
    background-color: black;
  }
}

.img-place-hold {

  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: img-place-hold;
  animation-name: img-place-hold;
  font-weight: 700;
  font-size: 24px;
  word-break: break-word;
}



/* 


@media (min-width: 768px) {

  button.btn {
    font-size: 48px;
    width: unset;

  }

}

input {

  font-weight: 900;
  font-size: 36px !important;
}

i.btn {
  font-size: 24px;
  font-weight: 300;

}


@media (min-width: 768px) {

  i.btn {
    font-size: 48px;
    font-weight: 300;

  }

}






@media (min-width: 768px) {

  p.btn {
    font-size: 48px;
    width: unset;
    color:black;
    background-color: purple;
    cursor: default !important;
    border-radius: 0px;
    font-weight: 300;

  }

} */